import React from 'react';
import {Form, InputNumber, Radio} from "antd";
import language from "../../../data/language.yaml";

class StepOne extends React.Component {
    state = {
        showOtherNumber: false
    }

    constructor(props) {
        super(props);
        this.handleChangeMapCount = this.handleChangeMapCount.bind(this);
        this.props.setForm(1, this.props.form);
    }

    handleChangeMapCount = event => {
        this.props.changeNumberOfMaps(event.target.value);
        this.setState({showOtherNumber: event.target.value === "" });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let otherNumber = this.state.showOtherNumber ? (
            <Form.Item label={language.mapBuyOnline.stepOne.maps[this.props.locale]}>
                {getFieldDecorator('other_number', {
                    initialValue: 4,
                    rules:        [
                        {
                            type:     "integer",
                            message:  language.mapBuyOnline.stepOne.howMany.error[this.props.locale],
                            required: true
                        }
                    ],
                    validateTrigger: false
                })(
                    <InputNumber name="other_number" min={4} max={20} onChange={this.props.changeNumberOfMaps}/>
                )}
            </Form.Item>
        ) : null;

        return (
            <div>
                <Form.Item label={language.mapBuyOnline.stepOne.howMany[this.props.locale]} colon={false} className="map-qty">
                    <div dangerouslySetInnerHTML={{__html: this.props.content}}></div>
                    {getFieldDecorator('number', {
                        initialValue:      this.props.order.items[0].quantity < 4 ? String(this.props.order.items[0].quantity) : '1',
                        onChange:          this.handleChangeMapCount,
                        rules:             [
                            {
                                type:     "integer",
                                message:  language.mapBuyOnline.stepOne.howMany.error[this.props.locale],
                                required: !this.state.showOtherNumber,
                            }
                        ],
                        validateTrigger: false
                    })(
                        <Radio.Group name="number" buttonStyle="solid">
                            <Radio.Button value="1"><img src="/images/map-single.png" alt="" /><span>1</span></Radio.Button>
                            <Radio.Button value="2"><img src="/images/map-double.png" alt="" /><span>2</span></Radio.Button>
                            <Radio.Button value="3"><img src="/images/map-triple.png" alt="" /><span>3</span></Radio.Button>
                            <Radio.Button value=""><img src="/images/map-more.png" alt="" /><span>4+</span></Radio.Button>
                        </Radio.Group>
                    )}
                </Form.Item>
                {otherNumber}
            </div>
        );
    }
}

const StepOneForm = Form.create()(StepOne);

export default StepOneForm;

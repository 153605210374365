import React from "react";
import axios from 'axios';
import {Form, Icon, Button, Card, Row, Col, Alert, Typography} from 'antd';
import CheckoutSummary from "./CheckoutSummary";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFive from "./StepFive";
import {Elements, StripeProvider} from 'react-stripe-elements';
import NoSSR from 'react-no-ssr';
import './style.less';
import language from "../../../data/language.yaml";
import countries from "../../../data/countries";
import {trackMapPurchase} from "../../services/gtm";

const {Paragraph} = Typography;

class MapBuyOnline extends React.Component {
    form = 'purchase_map';

    prices = {
        delivery: {
            GB:    115,
            other: 135
        },
        map:     200
    };

    state = {
        agreements:         {
            agree_terms:   false,
            agree_privacy: false
        },
        cardElementFilled:  false,
        errors:             [],
        forms:              {
            1: undefined,
            2: undefined,
            3: undefined,
            4: undefined,
            5: undefined
        },
        nextButtonDisabled: false,
        nextButtonLabel:    language.mapBuyOnline.next[this.props.locale],
        order:              {
            items: [
                {
                    key:      'map',
                    name:     language.mapBuyOnline[this.props.locale],
                    quantity: 1,
                    price:    this.prices.map,
                    suffix:   language.mapBuyOnline.each[this.props.locale]
                },
                {
                    key:      'delivery',
                    name:     language.mapBuyOnline.delivery1[this.props.locale].replace('%1$s', countries.GB),
                    quantity: 1,
                    price:    this.prices.delivery.GB,
                    suffix:   null
                }
            ],
            total: this.prices.map + this.prices.delivery.GB
        },
        personal:           {
            forename:         '',
            surname:          '',
            email:            '',
            address_line_1:   '',
            address_line_2:   '',
            address_city:     '',
            address_postcode: '',
            address_country:  'GB'
        },
        processing:         false,
        secret:             false,
        showPreviousButton: false,
        step:               1,
        stripe:             {
            cardElement: null,
            elements:    null,
            key:         'cardElement-' + Date.now(),
            stripe:      null
        },
        success:            false
    };

    constructor(props) {
        super(props);
        this.cardElementReady     = this.cardElementReady.bind(this);
        this.changeAgreements     = this.changeAgreements.bind(this);
        this.changeCardElement    = this.changeCardElement.bind(this);
        this.changeCountry        = this.changeCountry.bind(this);
        this.changeNumberOfMaps = this.changeNumberOfMaps.bind(this);
        this.changePersonal       = this.changePersonal.bind(this);
        this.handlePrevious       = this.handlePrevious.bind(this);
        this.handleNext           = this.handleNext.bind(this);
        this.handleSubmit         = this.handleSubmit.bind(this);
        this.paymentCallback      = this.paymentCallback.bind(this);
        this.setForm              = this.setForm.bind(this);
    }

    calculateTotal = order => {
        order.total = 0;
        order.items.forEach((item, index) => {
            order.total += (item.quantity * item.price);
        });
        return order;
    }

    cardElementReady = (cardElement, elements, key, stripe) => {
        this.setState({stripe: {cardElement: cardElement, elements: elements, key: key, stripe: stripe}});
    }

    changeAgreements = event => {
        event.preventDefault();
        let agreements                = this.state.agreements;
        agreements[event.target.name] = event.target.checked;
        this.setState({agreements: agreements});
    }

    changeCardElement = event => {
        let cardElementFilled  = event.complete && event.error === undefined;
        let nextButtonDisabled = this.state.nextButtonDisabled;
        if (cardElementFilled && this.state.processing === false && this.state.nextButtonDisabled) {
            // the card element is filled, we're not processing a request right now, and the next button is disabled
            nextButtonDisabled = false;
        }
        this.setState({cardElementFilled: cardElementFilled, nextButtonDisabled: nextButtonDisabled});
    }

    changeNumberOfMaps(number) {
        if (isNaN(number) || number === "") {
            number = 4;
            this.setState({showOtherNumber: true});
        } else if (number < 4) {
            this.setState({showOtherNumber: false});
        }
        let order = this.calculateTotal({
            items: [
                {
                    key:      'map',
                    name:     language.mapBuyOnline[this.props.locale],
                    quantity: number,
                    price:    this.prices.map,
                    suffix:   language.mapBuyOnline.each[this.props.locale]
                },
                {
                    key:      'delivery',
                    name:     language.mapBuyOnline.delivery1[this.props.locale].replace('%1$s', countries[this.state.personal.address_country]),
                    quantity: 1,
                    price:    this.getDeliveryPrice(this.state.personal.address_country),
                    suffix:   null
                }
            ]
        });
        this.setState({order: order});
    }

    changeCountry = countryCode => {
        this.changePersonal({
            preventDefault: () => false,
            target:         {
                name:  'address_country',
                value: countryCode
            }
        });
    }

    changePersonal = event => {
        event.preventDefault();
        let personal                = this.state.personal;
        personal[event.target.name] = event.target.value;
        let order                   = this.state.order;
        if (event.target.name === 'address_country') {
            order.items[1].name  = language.mapBuyOnline.delivery1[this.props.locale].replace('%1$s', countries[this.state.personal.address_country]);
            order.items[1].price = this.getDeliveryPrice(event.target.value);
            order                = this.calculateTotal(order);
        }
        this.setState({
            order:    order,
            personal: personal
        });
    }

    getDeliveryPrice = countryCode => {
        return this.prices.delivery[this.prices.delivery.hasOwnProperty(countryCode) ? countryCode : 'other'];
    }

    getOrderData() {
        return {
            agreements: this.state.agreements,
            order:      this.state.order,
            personal:   this.state.personal,
            form:       this.form
        }
    }

    handleNext = event => {
        event.preventDefault();

        // Reset the errors
        this.setState({errors: []});

        if (this.state.step === 5) {
            if (this.state.cardElementFilled) {
                this.setState({nextButtonDisabled: true, processing: true});
                return this.handleSubmit(event);
            } else {
                this.setState({
                    errors:             [language.mapBuyOnline.cardError[this.props.locale]],
                    nextButtonDisabled: true
                });
                return;
            }
        }

        let form   = this.state.forms[this.state.step];
        let fields = [];
        switch (this.state.step) {
            case 1:
                fields = this.props.form.getFieldValue('number') ? ['number'] : ['other_number'];
                break;
            case 2:
                fields = [
                    'forename',
                    'surname',
                    'email',
                    'address_line_1',
                    'address_line_2',
                    'address_city',
                    'address_postcode'
                ];
                break;
            case 3:
                fields = [
                    'agree_terms',
                    'agree_privacy'
                ];
                break;
            default:
                break;
        }

        if (form) {
            form.validateFieldsAndScroll(fields, (err, values) => {
                if (err) {
                    this.setState({errors: [language.mapBuyOnline.formError[this.props.locale]]});
                    return false;
                }

                this.setState({step: this.state.step + 1, showPreviousButton: true});
            });
        }
    }

    handlePrevious = event => {
        event.preventDefault();
        let newValue = this.state.step;
        if (this.state.step === 5) {
            newValue--; // Go from step 5 to 3, skip over 4
        }
        if (this.state.step > 1) {
            newValue--;
            this.setState({step: newValue, showPreviousButton: newValue !== 1});
        }
        this.setState({nextButtonLabel: language.mapBuyOnline.next[this.props.locale]});
    }

    handleSubmit = (ev) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();

        // Use Elements to get a reference to the Card Element mounted somewhere
        // in your <Elements> tree. Elements will know how to find your Card Element
        // because only one is allowed.
        // See our getElement documentation for more:
        // https://stripe.com/docs/stripe-js/reference#elements-get-element
        const cardElement = this.state.stripe.elements.getElement('card');
        const secret      = this.state.secret;

        // See our confirmCardPayment documentation for more:
        // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment
        this.state.stripe.stripe.confirmCardPayment(secret, {
            payment_method: {
                card: cardElement
            }
        }).then((result) => {
            if (result.paymentIntent) {
                // Log the transaction here with GTM - todo upsell this to transactional data in Analytics
                trackMapPurchase(this.state.order.items[0].quantity);

                this.setState({
                    processing: false,
                    success:    true
                })
            } else {
                if (result.error) {
                    // const cardElement = this.state.stripe.elements.getElement('card');
                    let forms = this.state.forms;
                    forms[5]  = this.makeStripeForm();

                    this.setState({
                        errors:     [result.error.message],
                        forms:      forms,
                        processing: false
                    });
                }
            }
        });
    }

    makeStripeForm() {
        let key = 'stepFive-' + Date.now();
        return <Elements locale={this.props.locale}>
            <StepFive changeCardElement={this.changeCardElement} errors={this.state.errors} key={key}
                      orderData={this.getOrderData()} ready={this.cardElementReady} secret={this.state.secret}
                      locale={this.props.locale}/>
        </Elements>
    }

    paymentCallback = (result) => {
        if (typeof (result) === 'object') {
            if (result.data.success && result.data.data.secret) {
                let forms = this.state.forms;
                forms[5]  = this.makeStripeForm();

                this.setState({
                    errors:             [],
                    forms:              forms,
                    nextButtonDisabled: true,
                    nextButtonLabel:    language.mapBuyOnline.submitPayment[this.props.locale],
                    secret:             result.data.data.secret,
                    step:               5
                });
                return;
            } else {
                this.setState({
                    errors: (result.data.errors) ? result.data.errors : [language.mapBuyOnline.paymentError[this.props.locale]],
                    step:   5
                });
            }
        } else {
            this.setState({
                errors: [language.mapBuyOnline.requestError[this.props.locale]],
                step:   4
            });
        }
    };

    setForm = (step, form) => {
        let forms   = this.state.forms;
        forms[step] = form;
        this.setState({forms: forms});
    }

    stepIndicator(number) {
        return (
            <Row>
                <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}}>
                    <ul className={"active-step-" + {number}}>
                        <li id="step-1">{language.mapBuyOnline.stepIndicator.selection[this.props.locale]}</li>
                        <li id="step-2">{language.mapBuyOnline.stepIndicator.personal[this.props.locale]}</li>
                        <li id="step-3">{language.mapBuyOnline.stepIndicator.purchase[this.props.locale]}</li>
                    </ul>
                </Col>
            </Row>
        )
    }

    render() {
        let errors = this.state.errors.map((error) => {
            return <Alert key={error} type="error" message={error} banner/>
        });

        let checkoutSummary       = <CheckoutSummary form={this.props.form} {...this.state.order}
                                                     locale={this.props.locale}/>;
        let topCheckoutSummary    = null;
        let bottomCheckoutSummary = null;

        let stepIndicator = null;
        let stepForm      = null;

        switch (this.state.step) {
            case 1:
                stepForm              =
                    <StepOne changeNumberOfMaps={this.changeNumberOfMaps} order={this.state.order}
                             setForm={this.setForm} locale={this.props.locale}
                            content={this.props.content}/>;
                bottomCheckoutSummary = checkoutSummary;
                break;
            case 2:
                stepForm              = <StepTwo changeCountry={this.changeCountry} changePersonal={this.changePersonal}
                                                 personal={this.state.personal}
                                                 setForm={this.setForm} locale={this.props.locale}/>;
                bottomCheckoutSummary = checkoutSummary;
                break;
            case 3:
                topCheckoutSummary = checkoutSummary;
                stepForm           =
                    <StepThree changeAgreements={this.changeAgreements} agreements={this.state.agreements}
                               setForm={this.setForm} locale={this.props.locale}/>;
                break;
            case 4:
                if (this.state.errors.length === 0) {
                    axios
                        .post(`${process.env.GATSBY_API_URL}/wp-json/brew/v1/payment/create/`, this.getOrderData())
                        .then(this.paymentCallback)
                        .catch(() => {
                            // Handle error, we always assume success to hide invalid emails from people
                            this.paymentCallback(false);
                        });
                    stepForm = <Paragraph>{language.mapBuyOnline.settingPayment[this.props.locale]}</Paragraph>
                } else {
                    stepForm = <Paragraph>{language.mapBuyOnline.paymentError[this.props.locale]}</Paragraph>
                }

                break;
            case 5:
                topCheckoutSummary = checkoutSummary;
                stepForm           = this.state.forms[5];
                break;
            default:
                stepForm = <Paragraph>Umm...</Paragraph>
                break;
        }

        let previousButton = this.state.showPreviousButton ? (
            <Form.Item>
                <Button type="secondary" htmlType="button" className="form-button previous" block
                        onClick={this.handlePrevious}
                        disabled={this.state.processing}>
                    {language.mapBuyOnline.back[this.props.locale]}
                </Button>
            </Form.Item>) : null;

        return (this.state.success === false) ? (
            <NoSSR>
                <StripeProvider apiKey={process.env.GATSBY_STRIPE_PUBLIC_API_KEY}>
                    <div>
                        {stepIndicator}
                        <Row>{topCheckoutSummary}</Row>
                        <Row gutter={70}>
                            <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}}>
                                <Card bordered={false} hoverable={false} className="map-buy-online-form-wrapper">
                                    <Form className="snowdonia-map-form">
                                        {stepForm}

                                        {errors}
                                        <Row>
                                            <Col xs={{span: 12}} className="prev-col">
                                                {previousButton}
                                            </Col>
                                            <Col xs={{span: 12}} className="next-col">
                                                <Form.Item>
                                                    <Button type="primary" htmlType="button"
                                                            className="btn btn-secondary" block
                                                            onClick={this.handleNext}
                                                            disabled={this.state.nextButtonDisabled}>
                                                        <span>{this.state.nextButtonLabel}</span>
                                                        <span className="icon arrow"></span>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                        <Row>{bottomCheckoutSummary}</Row>
                    </div>
                </StripeProvider>
            </NoSSR>
        ) : (
            <div>
                <Row gutter={70}>
                    <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}}>
                        <Card style={{marginBottom: "50px"}} bordered={false} hoverable={false}
                              className="member-update-form-wrapper">
                            <Paragraph style={{textAlign: 'center'}}>
                                <Icon type="check-circle" className="success"/>
                            </Paragraph>
                            <Paragraph
                                style={{textAlign: 'center'}}>{language.mapBuyOnline.thankYou[this.props.locale]}</Paragraph>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

const WrappedMapBuyOnlineForm = Form.create()(MapBuyOnline);

export default WrappedMapBuyOnlineForm
